//  ВОЗМОЖНЫЕ РОЛИ
//  admin
//  ge_employee
//  employee
//  franchisee
//  tester
//  partner
//  agent
//  partner_service

export const MENU_CONFIG = {
    franchisee_reports: {
        // Пример
        admin: false,
    },
    agent_reports: {},
    partner_reports: {},
    admin_locations_reports: {},
};
