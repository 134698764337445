export const tariffOptionsSessions = [
    { id: '1', name: '1 (standart)' },
    { id: '2', name: '2 (first_hour_free_old)' },
    { id: '3', name: '3 (special)' },
    { id: '4', name: '4 (first_hour_free_2)' },
    { id: '5', name: '5 (first_hour_free)' },
    { id: '6', name: '6 (29_per_day_partner)' },
    { id: '7', name: '7 (airport)' },
    { id: '8', name: '8 (crimea)' },
    { id: '9', name: '9 (sheregesh)' },
    { id: '10', name: '10 (30min_free)' },
    { id: '11', name: '11 (Swim_pool)' },
    { id: '12', name: '12 (new100)' },
    { id: '13', name: '13 (tele2_special)' },
    { id: '14', name: '14 (new_standart_def)' },
    { id: '15', name: '15 (first_session_new_def)' },
    { id: '16', name: '16 (Crimea_VIP)' },
    { id: '17', name: '17 (Crimea_Guest)' },
    { id: '18', name: '18 (Moscow Central Ring)' },
    { id: '19', name: '19 (for_tele2_premium)' },
    { id: '20', name: '20 (for_tele2_sub)' },
    { id: '21', name: '21 (exhibition_2h_free)' },
    { id: '22', name: '22 (exhibition_3h_free)' },
    { id: '23', name: '23 (simple_daily)' },
    { id: '24', name: '24 (15min_free_RU_&_UZ)' },
    { id: '25', name: '25 (100hour_200day)' },
    { id: '26', name: '26 (30min_free_100_daily)' },
    { id: '27', name: '27 (2023_first_session_tariff)' },
    { id: '28', name: '28 (2023_ret_session_tariff)' },
    { id: '29', name: '29 (30min_free_1123)' },
    { id: '30', name: '30 (Tomsk1_1123)' },
    { id: '31', name: '31 (Tomsk2_1123)' },
    { id: '32', name: '32 (Siberia1_70_130)' },
    { id: '33', name: '33 (Siberia2_130)' },
    { id: '34', name: '34 (special_limited)' },
    { id: '35', name: '35 (Uz2)' },
    { id: '36', name: '36 (MTS_office)' },
];

export const tariffOptionsLocations = [
    { id: '1', name: '1 (standart)' },
    { id: '2', name: '2 (first_hour_free_old)' },
    { id: '3', name: '3 (special)' },
    { id: '4', name: '4 (first_hour_free_2)' },
    { id: '5', name: '5 (first_hour_free)' },
    { id: '6', name: '6 (29_per_day_partner)' },
    { id: '7', name: '7 (airport)' },
    { id: '8', name: '8 (crimea)' },
    { id: '9', name: '9 (sheregesh)' },
    { id: '10', name: '10 (30min_free)' },
    { id: '11', name: '11 (Swim_pool)' },
    { id: '12', name: '12 (new100)' },
    { id: '15', name: '15 (first_session_new_def)' },
    { id: '16', name: '16 (Crimea_VIP)' },
    { id: '17', name: '17 (Crimea_Guest)' },
    { id: '18', name: '18 (Moscow Central Ring)' },
    { id: '21', name: '21 (exhibition_2h_free)' },
    { id: '22', name: '22 (exhibition_3h_free)' },
    { id: '23', name: '23 (simple_daily)' },
    { id: '24', name: '24 (15min_free_RU_&_UZ)' },
    { id: '25', name: '25 (100hour_200day)' },
    { id: '26', name: '26 (30min_free_100_daily)' },
    { id: '27', name: '27 (2023_first_session_tariff)' },
    { id: '28', name: '28 (2023_ret_session_tariff)' },
    { id: '29', name: '29 (30min_free_1123)' },
    { id: '30', name: '30 (Tomsk1_1123)' },
    { id: '31', name: '31 (Tomsk2_1123)' },
    { id: '32', name: '32 (Siberia1_70_130)' },
    { id: '33', name: '33 (Siberia2_130)' },
    { id: '33', name: '35 (Uz2)' },
    { id: '36', name: '36 (MTS_office)' },
];

export const tariffSpecialOptions = [
    { id: '3', name: '3 (special)' },
    { id: '6', name: '6 (29_per_day_partner)' },
    { id: '34', name: '34 (special_limited)' },
];
